import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  isListLoading: false,
  integrationList: [],
  activeIntegrations: [],
  fetchLogs: null,
  integrationData: {},
  error: null,
  succMsg: null,
  errMsg: null,
  apiCall: {},
  apiCallError: null,
};

export const integrationReducer = createReducer(initialState, {
  [types.GET_INTEGRATIONS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_INTEGRATIONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      integrationList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_INTEGRATIONS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ACTIVE_INTEGRATIONS](state) {
    return Object.assign({}, state, {
      ...state,
      isListLoading: true,
      error: null,
    });
  },
  [types.GET_ACTIVE_INTEGRATIONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      activeIntegrations: action.payload,
      isListLoading: false,
    });
  },
  [types.GET_ACTIVE_INTEGRATIONS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isListLoading: false,
    });
  },

  [types.GET_INTEGRATION_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_INTEGRATION_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      integrationData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_INTEGRATION_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_INTEGRATION](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.ADD_INTEGRATION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
    });
  },
  [types.ADD_INTEGRATION_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_INTEGRATION_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.UPDATE_INTEGRATION_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
    });
  },
  [types.UPDATE_INTEGRATION_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_INTEGRATION_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.DELETE_INTEGRATION_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
    });
  },
  [types.DELETE_INTEGRATION_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.CALL_INTEGRATION_ACTION_API](state) {
    return Object.assign({}, state, {
      isLoading: true,
      apiCall: {},
      error: null,
      apiCallError: null,
    });
  },
  [types.CALL_INTEGRATION_ACTION_API_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      apiCall: action.payload,
      isLoading: false,
    });
  },
  [types.CALL_INTEGRATION_ACTION_API_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      apiCallError: action.payload,
      isLoading: false,
    });
  },
  [types.GET_FETCH_LOGS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_FETCH_LOGS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      fetchLogs: action.payload,
      isLoading: false,
    });
  },
  [types.GET_FETCH_LOGS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.ACTIVATE_INTEGRATION_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.ACTIVATE_INTEGRATION_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
    });
  },
  [types.ACTIVATE_INTEGRATION_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      errMsg: action.payload,
      isLoading: false,
    });
  },
});
