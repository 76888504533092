import * as types from "../types/index";

const intialState = {
  isLoading: false,
  data: {},
  isCompanyRegistered: false,
  isUserExist: false,
  isError: false,
  isCompanyExist: false,
  cardToken: {},
  socialData: {},
  error: null,
  registerError: false,
};

export const signUp = (state = intialState, action) => {
  switch (action.type) {
    case types.RESET_SIGNUP_STORE:
      return {
        isLoading: false,
        data: {},
        isCompanyRegistered: false,
        isUserExist: false,
        isError: false,
        isCompanyExist: false,
        cardToken: {},
        socialData: {},
        error: null,
        registerError: false,
      };
    case types.GET_USER: {
      return {
        ...state,

        error: null,
      };
    }
    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        isUserExist: true,
        isError: true,
      };
    }
    case types.SERVER_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isError: true,
      };
    }
    case types.GET_USER_FAILURE: {
      return {
        ...state,
        isUserExist: false,
        isError: false,
        isLoading: false,
      };
    }
    case types.GET_COMPANY: {
      return {
        ...state,
        error: null,
      };
    }
    case types.GET_COMPANY_SUCCESS: {
      const { error } = action.payload;
      return {
        ...state,
        error: error,
        isError: true,
        isCompanyExist: true,
      };
    }
    case types.GET_COMPANY_FAILURE: {
      return {
        ...state,
        error: null,
        isError: false,
        isCompanyExist: false,
      };
    }
    case types.ADD_CARD:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_CARD_SUCCESS: {
      const { cardToken } = action.payload;
      return {
        ...state,
        error: null,
        isLoading: false,
        cardToken,
      };
    }
    case types.ADD_CARD_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case types.SOCIAL_LOGIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        socialData: data,
      };
    }
    case types.REGISTERING_USER: {
      return {
        ...state,
        isLoading: true,
        error: null,
        data: {},
        registerError: false,
      };
    }
    case types.REGISTERING_USER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data,
        isLoading: false,
      };
    }
    case types.REGISTERING_USER_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        registerError: true,
      };
    }
    case types.GET_SIGN_UP_PLANS: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case types.GET_SIGN_UP_PLANS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        signupPlans: data,
        isLoading: false,
      };
    }
    case types.GET_SIGN_UP_PLANS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
      };
    }
    case types.DELETE_COMPANY: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case types.DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.DELETE_COMPANY_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
      };
    }
    case types.GET_LEAD_DETAILS: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case types.GET_LEAD_DETAILS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        leadData: data,
        isLoading: false,
      };
    }
    case types.GET_LEAD_DETAILS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error: error,
        leadData: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
