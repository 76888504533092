import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "@utils/api.config";

const initialState = {
  isLoading: false,
  hours: {},
  userHourList: {},
  userHourListLoading: false,
  hourList: {},
  error: null,
  hourTypeSummaryList: {},
  hourTypeSummaryListLoading: false,
  hourSummary: {},
  hourLoading: false,
  approvalUserList: {},
  changeApprovalUserList: {},
  approvalDetails: {},
  approvalDetailsSimplified: {},
  approvalDetailsLoading: false,
  approvalSummary: {},
  changeDetails: {},
  changeDetailsSimplified: {},
  changeDetailsLoading: false,
  changeSummary: {},
  approvalSuccess: null,
  approvalError: null,
  addonList: {},
  addonLoading: false,
  fixedAddonList: {},
  fixedAddonLoading: false,
  userAddonList: {},
  userAddonListLoading: false,
  addonTypeSummaryList: {},
  addonTypeSummaryListLoading: false,
  fixedAddonTypeSummaryList: {},
  fixedAddonTypeSummaryListLoading: false,
  batchChangeHourList: {},
  checkInSuccess: null,
  checkInError: null,
  checkInHour: {},
  createCheckInSuccess: null,
  isAttendanceLoading: false,
  userAttendance: null,
  guestAttendance: null,
  lastUserAttendance: null,
  createAttendanceSuccess: null,
  attendanceSuccess: null,
  previewMultiProjectHourSuccess: null,
  previewMultiProjectHourError: null,
  batchGroupDataTable: {},
  batchGroupDataByUser: {},
  hoursAddonsByUser: {},
  hoursByProject: {},
  batchGroups: [],
  typeSummary: {},
  batchGroupData: {},
  isCheckoutSubmitting: false,
  isCreateHourSubmitting: false,
  isUpdateHourSubmitting: false,
};

export const hourReducer = createReducer(initialState, {
  [types.GET_USERS_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      userHourListLoading: true,
      error: null,
    });
  },
  [types.GET_USERS_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userHourList: {
        ...state.userHourList,
        [action.meta.id]: action.payload,
      },
      userHourListLoading: false,
    });
  },
  [types.GET_USERS_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      userHourListLoading: false,
    });
  },
  [types.GET_HOUR_TYPE_SUMMARY](state) {
    return Object.assign({}, state, {
      ...state,
      hourTypeSummaryListLoading: true,
      error: null,
    });
  },
  [types.GET_HOUR_TYPE_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hourTypeSummaryList: {
        ...state.hourTypeSummaryList,
        [action.meta.id]: action.payload,
      },
      hourTypeSummaryListLoading: false,
    });
  },
  [types.GET_HOUR_TYPE_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      hourTypeSummaryListLoading: false,
    });
  },
  [types.GET_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      hourLoading: true,
      error: null,
    });
  },
  [types.GET_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hourList: action.payload,
      hourLoading: false,
    });
  },
  [types.GET_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      hourLoading: false,
    });
  },
  [types.GET_BATCH_GROUPS](state) {
    return Object.assign({}, state, {
      ...state,
      batchGroups: [],
      hourLoading: true,
      error: null,
    });
  },
  [types.GET_BATCH_GROUPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      batchGroups: action.payload,
      hourLoading: false,
    });
  },
  [types.GET_BATCH_GROUPS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      hourLoading: false,
    });
  },
  [types.GET_BATCH_GROUP_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      batchGroupData: {},
      isLoading: true,
      error: null,
    });
  },
  [types.GET_BATCH_GROUP_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      batchGroupData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_BATCH_GROUP_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_SINGLE_DAY_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      hours: {},
      hourLoading: true,
      error: null,
    });
  },
  [types.GET_SINGLE_DAY_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hours: action.payload,
      hourLoading: false,
    });
  },
  [types.GET_SINGLE_DAY_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      hourLoading: false,
    });
  },
  [types.GET_BATCH_GROUP_TYPES_SUMMARY](state) {
    return Object.assign({}, state, {
      ...state,
      typeSummary: {},
      error: null,
    });
  },
  [types.GET_BATCH_GROUP_TYPES_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      typeSummary: action.payload,
    });
  },
  [types.GET_BATCH_GROUP_TYPES_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
    });
  },
  [types.GET_HOURS_SUMMARY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_HOURS_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hourSummary: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HOURS_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ADDONS_SUMMARY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_ADDONS_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addonSummary: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ADDONS_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_APPROVAL_DETAILS](state) {
    return Object.assign({}, state, {
      approvalDetailsLoading: true,
      approvalDetails: {},
      error: null,
    });
  },
  [types.GET_APPROVAL_DETAILS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalDetails: action.payload,
      approvalDetailsLoading: false,
    });
  },
  [types.GET_APPROVAL_DETAILS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      approvalDetailsLoading: false,
    });
  },
  [types.GET_APPROVAL_DETAILS_SIMPLIFIED](state) {
    return Object.assign({}, state, {
      approvalDetailsLoading: true,
      approvalDetailsSimplified: {},
      error: null,
    });
  },
  [types.GET_APPROVAL_DETAILS_SIMPLIFIED_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalDetailsSimplified: action.payload,
      approvalDetailsLoading: false,
    });
  },
  [types.GET_APPROVAL_DETAILS_SIMPLIFIED_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      approvalDetailsLoading: false,
    });
  },
  [types.GET_CHANGE_DETAILS](state) {
    return Object.assign({}, state, {
      changeDetailsLoading: true,
      changeDetails: {},
      error: null,
    });
  },
  [types.GET_CHANGE_DETAILS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      changeDetails: action.payload,
      changeDetailsLoading: false,
    });
  },
  [types.GET_CHANGE_DETAILS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      changeDetailsLoading: false,
    });
  },
  [types.GET_CHANGE_DETAILS_SIMPLIFIED](state) {
    return Object.assign({}, state, {
      changeDetailsLoading: true,
      changeDetailsSimplified: {},
      error: null,
    });
  },
  [types.GET_CHANGE_DETAILS_SIMPLIFIED_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      changeDetailsSimplified: action.payload,
      changeDetailsLoading: false,
    });
  },
  [types.GET_CHANGE_DETAILS_SIMPLIFIED_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      changeDetailsLoading: false,
    });
  },
  [types.GET_APPROVAL_SUMMARY](state) {
    return Object.assign({}, state, {
      isLoading: true,
      approvalSummary: {},
      error: null,
    });
  },
  [types.GET_APPROVAL_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalSummary: action.payload,
      isLoading: false,
    });
  },
  [types.GET_APPROVAL_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_CHANGE_SUMMARY](state) {
    return Object.assign({}, state, {
      isLoading: true,
      changeSummary: {},
      error: null,
    });
  },
  [types.GET_CHANGE_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      changeSummary: action.payload,
      isLoading: false,
    });
  },
  [types.GET_CHANGE_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_APPROVAL_USERS](state) {
    return Object.assign({}, state, {
      isLoading: true,
      approvalUserList: {},
      error: null,
    });
  },
  [types.GET_APPROVAL_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalUserList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_APPROVAL_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_CHANGE_APPROVAL_USERS](state) {
    return Object.assign({}, state, {
      isLoading: true,
      changeApprovalUserList: {},
      error: null,
    });
  },
  [types.GET_CHANGE_APPROVAL_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      changeApprovalUserList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_CHANGE_APPROVAL_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.APPROVE_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.REJECT_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.REJECT_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.REJECT_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.APPROVE_ALL](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_ALL_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_ALL_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.REJECT_ALL](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.REJECT_ALL_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.REJECT_ALL_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.APPROVE_CHANGE_ALL](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_CHANGE_ALL_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_CHANGE_ALL_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.CHANGE_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.CHANGE_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.CHANGE_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.APPROVE_ADDONS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_ADDONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_ADDONS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.REJECT_ADDONS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.REJECT_ADDONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.REJECT_ADDONS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.CHANGE_ADDONS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.CHANGE_ADDONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.CHANGE_ADDONS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.GET_ADDONS](state) {
    return Object.assign({}, state, {
      ...state,
      addonLoading: true,
      error: null,
    });
  },
  [types.GET_ADDONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addonList: action.payload,
      addonLoading: false,
    });
  },
  [types.GET_ADDONS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      addonLoading: false,
    });
  },
  [types.GET_USERS_ADDONS](state) {
    return Object.assign({}, state, {
      ...state,
      userAddonListLoading: true,
      error: null,
    });
  },
  [types.GET_USERS_ADDONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userAddonList: {
        ...state.userAddonList,
        [action.meta.id]: action.payload,
      },
      userAddonListLoading: false,
    });
  },
  [types.GET_USERS_ADDONS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      userAddonListLoading: false,
    });
  },
  [types.GET_ADDON_TYPE_SUMMARY](state) {
    return Object.assign({}, state, {
      ...state,
      addonTypeSummaryListLoading: true,
      error: null,
    });
  },
  [types.GET_ADDON_TYPE_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addonTypeSummaryList: {
        ...state.addonTypeSummaryList,
        [action.meta.id]: action.payload,
      },
      addonTypeSummaryListLoading: false,
    });
  },
  [types.GET_ADDON_TYPE_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      addonTypeSummaryListLoading: false,
    });
  },
  [types.GET_FIXED_ADDON_TYPE_SUMMARY](state) {
    return Object.assign({}, state, {
      ...state,
      fixedAddonTypeSummaryListLoading: true,
      error: null,
    });
  },
  [types.GET_FIXED_ADDON_TYPE_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      fixedAddonTypeSummaryList: {
        ...state.fixedAddonTypeSummaryList,
        [action.meta.id]: action.payload,
      },
      fixedAddonTypeSummaryListLoading: false,
    });
  },
  [types.GET_FIXED_ADDON_TYPE_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      fixedAddonTypeSummaryListLoading: false,
    });
  },
  [types.APPROVE_BATCH](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_BATCH_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_BATCH_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.REJECT_BATCH](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.REJECT_BATCH_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.REJECT_BATCH_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.GET_BATCH_RULES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      batchChangeHourList: {},
      error: null,
    });
  },
  [types.GET_BATCH_RULES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      batchChangeHourList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_BATCH_RULES_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.CHANGE_BATCH](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.CHANGE_BATCH_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.CHANGE_BATCH_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.GET_CHECKIN_HOUR](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      checkInHour: {},
      checkInError: null,
    });
  },
  [types.GET_CHECKIN_HOUR_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      checkInHour: action.payload,
      isLoading: false,
    });
  },
  [types.GET_CHECKIN_HOUR_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...initialState,
      checkInError: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_CHECKIN_HOUR](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      checkInError: null,
      checkInSuccess: null,
      createCheckInSuccess: null,
    });
  },
  [types.CREATE_CHECKIN_HOUR_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      createCheckInSuccess: action.payload,
      checkInError: null,
    });
  },
  [types.CREATE_CHECKIN_HOUR_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      checkInError: action.payload,
      createCheckInSuccess: null,
      isLoading: false,
    });
  },
  [types.UPDATE_CHECKIN_HOUR](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      checkInError: null,
      checkInSuccess: null,
    });
  },
  [types.UPDATE_CHECKIN_HOUR_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      checkInSuccess: action.payload,
      checkInError: null,
    });
  },
  [types.UPDATE_CHECKIN_HOUR_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      checkInError: action.payload,
      checkInSuccess: null,
      isLoading: false,
    });
  },
  [types.DELETE_CHECKIN_HOUR](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      checkInError: null,
      checkInSuccess: null,
    });
  },
  [types.DELETE_CHECKIN_HOUR_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      checkInSuccess: action.payload,
      checkInError: null,
    });
  },
  [types.DELETE_CHECKIN_HOUR_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      checkInError: action.payload,
      checkInSuccess: null,
      isLoading: false,
    });
  },
  [types.APPROVE_BATCH_GROUP](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_BATCH_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_BATCH_GROUP_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.REJECT_BATCH_GROUP](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.REJECT_BATCH_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.REJECT_BATCH_GROUP_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.CHANGE_BATCH_GROUP](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.CHANGE_BATCH_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.CHANGE_BATCH_GROUP_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.GET_USER_ATTENDANCE](state) {
    return Object.assign({}, state, {
      ...state,
      isAttendanceLoading: true,
      userAttendance: null,
      attendanceError: null,
    });
  },
  [types.GET_USER_ATTENDANCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userAttendance: action.payload,
      isAttendanceLoading: false,
    });
  },
  [types.GET_USER_ATTENDANCE_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...initialState,
      attendanceError: action.payload,
      isAttendanceLoading: false,
    });
  },
  [types.GET_GUEST_ATTENDANCE](state) {
    return Object.assign({}, state, {
      ...state,
      isAttendanceLoading: true,
      guestAttendance: null,
      attendanceError: null,
    });
  },
  [types.GET_GUEST_ATTENDANCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      guestAttendance: action.payload,
      isAttendanceLoading: false,
    });
  },
  [types.GET_GUEST_ATTENDANCE_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...initialState,
      attendanceError: action.payload,
      isAttendanceLoading: false,
    });
  },
  [types.CREATE_USER_ATTENDANCE](state) {
    return Object.assign({}, state, {
      ...state,
      isAttendanceLoading: true,
      attendanceError: null,
      attendanceSuccess: null,
      createAttendanceSuccess: null,
    });
  },
  [types.CREATE_USER_ATTENDANCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isAttendanceLoading: false,
      createAttendanceSuccess: action.payload,
      attendanceError: null,
    });
  },
  [types.CREATE_USER_ATTENDANCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      attendanceError: action.payload,
      createAttendanceSuccess: null,
      isAttendanceLoading: false,
    });
  },
  [types.UPDATE_USER_ATTENDANCE](state) {
    return Object.assign({}, state, {
      ...state,
      isAttendanceLoading: true,
      attendanceError: null,
      attendanceSuccess: null,
      createAttendanceSuccess: null,
    });
  },
  [types.UPDATE_USER_ATTENDANCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isAttendanceLoading: false,
      attendanceSuccess: action.payload,
      attendanceError: null,
    });
  },
  [types.UPDATE_USER_ATTENDANCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      attendanceError: action.payload,
      attendanceSuccess: null,
      isAttendanceLoading: false,
    });
  },
  [types.CREATE_MULTI_PROJECT_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      isCreateHourSubmitting: true,
      error: null,
      multiProjectHourSuccess: null,
      multiProjectHourError: null,
    });
  },
  [types.CREATE_MULTI_PROJECT_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isCreateHourSubmitting: false,
      multiProjectHourSuccess: action.payload,
      multiProjectHourError: null,
    });
  },
  [types.CREATE_MULTI_PROJECT_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      multiProjectHourError: action.payload,
      multiProjectHourSuccess: null,
      isCreateHourSubmitting: false,
    });
  },
  [types.UPDATE_BATCH_GROUP](state) {
    return Object.assign({}, state, {
      ...state,
      isUpdateHourSubmitting: true,
      error: null,
      multiProjectHourSuccess: null,
      multiProjectHourError: null,
    });
  },
  [types.UPDATE_BATCH_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isUpdateHourSubmitting: false,
      multiProjectHourSuccess: action.payload,
      multiProjectHourError: null,
    });
  },
  [types.UPDATE_BATCH_GROUP_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      multiProjectHourError: action.payload,
      multiProjectHourSuccess: null,
      isUpdateHourSubmitting: false,
    });
  },
  [types.DELETE_BATCH_GROUP](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      multiProjectHourSuccess: null,
      multiProjectHourError: null,
    });
  },
  [types.DELETE_BATCH_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      multiProjectHourSuccess: action.payload,
      multiProjectHourError: null,
    });
  },
  [types.DELETE_BATCH_GROUP_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      multiProjectHourError: action.payload,
      multiProjectHourSuccess: null,
      isLoading: false,
    });
  },
  [types.GET_USER_ATTENDANCE_LAST](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      lastUserAttendance: null,
      attendanceError: null,
    });
  },
  [types.GET_USER_ATTENDANCE_LAST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      lastUserAttendance: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_ATTENDANCE_LAST_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...state,
      attendanceError: action.payload,
      isLoading: false,
    });
  },
  [types.PREVIEW_MULTI_PROJECT_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      previewMultiProjectHourSuccess: null,
      previewMultiProjectHourError: null,
    });
  },
  [types.PREVIEW_MULTI_PROJECT_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      previewMultiProjectHourSuccess: action.payload,
    });
  },
  [types.PREVIEW_MULTI_PROJECT_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      previewMultiProjectHourError: action.payload,
      isLoading: false,
    });
  },
  [types.GET_BATCH_GROUP_DATA_BY_PROJECT](state) {
    return Object.assign({}, state, {
      ...state,
      batchGroupDataTable: {},
      isLoading: true,
      error: null,
    });
  },
  [types.GET_BATCH_GROUP_DATA_BY_PROJECT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      batchGroupDataTable: action.payload,
      isLoading: false,
    });
  },
  [types.GET_BATCH_GROUP_DATA_BY_PROJECT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_BATCH_GROUP_DATA_BY_USER](state) {
    return Object.assign({}, state, {
      ...state,
      batchGroupDataByUser: {},
      isLoading: true,
      error: null,
    });
  },
  [types.GET_BATCH_GROUP_DATA_BY_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      batchGroupDataByUser: action.payload,
      isLoading: false,
    });
  },
  [types.GET_BATCH_GROUP_DATA_BY_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HOURS_ADDON_BY_USER](state) {
    return Object.assign({}, state, {
      ...state,
      hoursAddonsByUser: {},
      isLoading: true,
      error: null,
    });
  },
  [types.GET_HOURS_ADDON_BY_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hoursAddonsByUser: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HOURS_ADDON_BY_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.GET_HOURS_BY_PROJECT](state) {
    return Object.assign({}, state, {
      ...state,
      hoursByProject: {},
      isLoading: true,
      error: null,
    });
  },
  [types.GET_HOURS_BY_PROJECT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hoursByProject: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HOURS_BY_PROJECT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.APPROVE_ALL_USERS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_ALL_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_ALL_USERS_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.DELETE_BATCH_GROUPS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      multiProjectHourSuccess: null,
      multiProjectHourError: null,
    });
  },
  [types.DELETE_BATCH_GROUPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      multiProjectHourSuccess: action.payload,
      multiProjectHourError: null,
    });
  },
  [types.DELETE_BATCH_GROUPS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      multiProjectHourError: action.payload,
      multiProjectHourSuccess: null,
      isLoading: false,
    });
  },
  [types.CREATE_CHECKOUT_HOUR](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      isCheckoutSubmitting: true,
      error: null,
      checkoutHourSuccess: null,
      checkoutHourError: null,
    });
  },
  [types.CREATE_CHECKOUT_HOUR_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      isCheckoutSubmitting: false,
      checkoutHourSuccess: action.payload,
      checkoutHourError: null,
    });
  },
  [types.CREATE_CHECKOUT_HOUR_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      checkoutHourError: action.payload,
      checkoutHourSuccess: null,
      isCheckoutSubmitting: false,
      isLoading: false,
    });
  },
  [types.GET_FIXED_ADDONS](state) {
    return Object.assign({}, state, {
      ...state,
      fixedAddonLoading: true,
      error: null,
    });
  },
  [types.GET_FIXED_ADDONS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      fixedAddonList: action.payload,
      fixedAddonLoading: false,
    });
  },
  [types.GET_FIXED_ADDONS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      fixedAddonLoading: false,
    });
  },
});
