export const normalize = ({ data = [], key }) => {
  let obj = {};
  let ids = [];
  if (data && Array.isArray(data)) {
    data.forEach((element) => {
      obj[element[key]] = element;
      ids.push(element[key]);
    });
    return { data: obj, ids };
  }
};

export const normalizeForReactSelect = ({ data }) => {
  return data.map((entry) => ({
    value: entry.id.toString(),
    label: entry.name,
  }));
};

export const getAmountFromFloatDigit = (inputDiff) => {
  var minuts = "";
  var hours = "";
  hours = inputDiff.toString().split(".")[0];
  minuts = inputDiff.toString().split(".")[1];
  if (parseInt(minuts, 10) > 6) {
    if (parseInt(minuts, 10) < 10) {
      hours =
        parseInt(inputDiff.toString().split(".")[0], 10) +
        Math.floor(
          (parseInt(inputDiff.toString().split(".")[1], 10) * 10) / 60
        );
      minuts = (parseInt(inputDiff.toString().split(".")[1], 10) * 10) % 60;
      if (minuts < 10) {
        minuts = 0 + "" + minuts;
      }
    }
    if (parseInt(minuts, 10) > 60) {
      hours =
        parseInt(inputDiff.toString().split(".")[0], 10) +
        Math.floor(parseInt(inputDiff.toString().split(".")[1], 10) / 60);
      minuts = parseInt(inputDiff.toString().split(".")[1], 10) % 60;
      if (minuts < 10) {
        minuts = 0 + "" + minuts;
      }
    }
  } else {
    minuts = minuts + 0;
  }
  if (isNaN(minuts)) {
    minuts = 0;
  }
  return hours + "." + minuts;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isValidEmail = (email) => {
  // The email RegEx Referred from: https://emailregex.com/
  /* eslint-disable no-useless-escape */
  const emailRegx = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return emailRegx.test(email) || false;
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export const generateLabel = (key) => {
  // replace _ with space and capitalize 1st char
  return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ");
};
