import { combineReducers } from "redux";
import { signUp } from "../reducers/signUp";
import { signIn } from "../reducers/signIn";
import { logOut } from "../reducers/logout";
import { stripeReducer } from "../reducers/stripe";
import { profile } from "../reducers/profile";
import { commonReducer } from "@/redux/reducers/common";
import { departmentReducer } from "../reducers/department";
import { hourReducer } from "../reducers/hour";
import { companyReducer } from "../reducers/company";
import { reportReducer } from "../reducers/report";
import { devianceReducer } from "../reducers/deviance";
import { categoryReducer } from "../reducers/category";
import { hmskTemplateReducer } from "../reducers/hmskTemplate";
import { hmskCheckListReducer } from "../reducers/hmskCheckList";
import { rulesReducer } from "../reducers/rules";
import { filters } from "@/redux/reducers/filters";
import { task } from "../reducers/task";
import { projectReducer } from "@/redux/reducers/projects";
import { customer } from "../reducers/customer";
import { hourTypes } from "../reducers/hourTypes";
import { planningReducer } from "../reducers/planning";
import { addonTypes } from "../reducers/addonTypes";
import { geofenceReducer } from "@/redux/reducers/geoFence";
import { userGroupsReducer } from "../reducers/userGroups";
import { schedulesReducer } from "@/redux/reducers/schedules";
import { holidayReducer } from "../reducers/holidays";
import { dashboardReducer } from "@/redux/reducers/dashboard";
import { itemReducer } from "@/redux/reducers/item";
import { leaveReducer } from "../reducers/leave";
import { fileUploadReducer } from "@/redux/reducers/fileUpload";
import { onboardingReducer } from "../reducers/onBoarding";
import { tagReducer } from "../reducers/tag";
import { sickLeaveReducer } from "../reducers/sickLeave";
import { integrationReducer } from "../reducers/integrations";

const appReducer = combineReducers({
  signUp,
  signIn,
  logOut,
  stripeReducer,
  filters,
  projectReducer,
  profile,
  commonReducer,
  departmentReducer,
  hourReducer,
  task,
  customer,
  hourTypes,
  companyReducer,
  reportReducer,
  devianceReducer,
  categoryReducer,
  hmskTemplateReducer,
  hmskCheckListReducer,
  planningReducer,
  rulesReducer,
  addonTypes,
  geofenceReducer,
  userGroupsReducer,
  holidayReducer,
  schedulesReducer,
  dashboardReducer,
  integrationReducer,
  itemReducer,
  leaveReducer,
  fileUploadReducer,
  onboardingReducer,
  tagReducer,
  sickLeaveReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "logout/POST_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
